import button from './button.js'
import custom from './custom.js'
import formElement from './formElement.js'
import headline from './headline.js'
import image from './image.js'
import link from './link.js'
import menu from './menu.js'
import orderBump from './orderBump.js'
import orderSummary from './orderSummary.js'
import paragraph from './paragraph.js'
import paymentForm from './paymentForm.js'
import productList from './productList.js'
import progressBar from './progressBar.js'
import row from './row.js'
import section from './section.js'
import social from './social.js'
import spacer from './spacer.js'
import timer from './timer.js'
import twoStepForm from './twoStepForm.js'
import video from './video.js'
import formSpacer from './formSpacer'
import formButton from './formButton'
import imageSlider from './imageSlider'
import container from './container'
import faq from './faq'
import beforeAfterSlider from './beforeAfterSlider'
import videoNative from './videoNative'
import buttonV2 from './buttonV2.js'
import expressCheckout from './expressCheckout.js'

export function findModel(type) {
    let arr = [
        expressCheckout,
        buttonV2,
        button,
       faq,
       beforeAfterSlider,
        videoNative,
        container,
        imageSlider,
        formButton,
        formSpacer,
        custom,
        formElement,
        headline,
        image,
        link,
        menu,
        orderBump,
        orderSummary,
        paragraph,
        paymentForm,
        productList,
        progressBar,
        row,
        section,
        social,
        spacer,
        timer,
        twoStepForm,
        video];
    for (let i = arr.length - 1; i >= 0; i--) {
        if (arr[i].tag===type) return arr[i];
    }
    return null
}

export default {
    expressCheckout,
    buttonV2,
    beforeAfterSlider,
    videoNative,
    faq,
    container,
    imageSlider,
    formSpacer,
    formButton,
    button,
    custom,
    formElement,
    headline,
    image,
    link,
    menu,
    orderBump,
    orderSummary,
    paragraph,
    paymentForm,
    productList,
    progressBar,
    row,
    section,
    social,
    spacer,
    timer,
    twoStepForm,
    video,
}
