<template>
  <fieldset :class="['input-field', {'active': isActive, 'has-max-length':maxLength>0,
  bold, textarea, small, 'extra-small':extraSmall, flat, autosize, 'is-save':isSave, 'is-invalid':hasError}]"
  :style="style">

    <legend v-if="label">
      {{ label }}

      <span class="is-optional" v-if="optional">(optional)</span>
      <span class="" style="font-size: 16px;margin-left: 5px;color: red;" v-else-if="required">*</span>

      <div v-if="!!info" class="info_icon" v-tippy="{interactive:interactive}" :content="info">
        <inline-svg :src="require('@/assets/img/info_icon.svg')"/>
      </div>

      <tippy v-else-if="!!$slots.info" :interactive="interactive">
        <template v-slot:trigger>
          <div class="info_icon">
            <inline-svg :src="require('@/assets/img/info_icon.svg')"/>
          </div>
        </template>
        <slot name="info"></slot>
      </tippy>
    </legend>

    <slot name="prepend" v-if="prepend" >
      <div class="prepend" @click="focusInput">{{ prepend }}</div>
    </slot>

    <div class="icon" v-if="icon">
      <inline-svg :src="icon" />
    </div>

	  <div v-if="autosize" aria-hidden="true" class="input-mirror " ref="mirror" v-html="$attrs.value"></div>

	  <textarea v-if="textarea" ref="input"
	         v-bind="$attrs"
            v-bind:value="inputVal"
            :maxlength="maxLength"
            :placeholder="placeholder"
	         @input="onInput($event.target.value)"
	         @change="$emit('change', $event.target.value)"
	         :class="{ 'is-invalid': hasError }"
          class="scroll"
	         @blur="isActive=false, wasActive=true"
	         @focus="onFocus"></textarea>
	  <input v-else ref="input"
	         v-bind="$attrs" :placeholder="placeholder"
	         v-bind:value="inputVal"
	         :maxlength="maxLength"
	         @input="onInput($event.target.value)"
	         @change="$emit('change', $event.target.value)"
	         :class="{ 'is-invalid': hasError }"
	         @blur="isActive=false, wasActive=true"
	         @focus="onFocus">

	  <slot name="append" v-if="append" >
		  <div class="append" @click="focusInput">{{ append }}</div>
	  </slot>
	  <slot name="append" v-else-if="!!$slots.append" >
		  <div class="append" @click="focusInput">
			  <slot name="append" ></slot>
		  </div>
	  </slot>

	  <span class="max-length" v-if="maxLength>0"
	        :class="{'red':(length>=maxLength)}">
		  {{length}} / {{maxLength}}
	  </span>

	  <!--	  v-else-if="!!$slots.info"-->

	  <template v-if="isCopy">
		  <a class="input_copy_btn"
		     v-tippy="{theme:'small-black',trigger:'mouseenter click',hideOnClick : false,duration:[350, 400]}"
		     :content="copyTooltip" @click="copyToClipboard()">
			  <inline-svg :src="require('@/assets/img/copy_icon.svg')"/>
		  </a>
	  </template>

	  <template v-if="isSave && !isSaved">
		  <a class="save_btn" @click="copyToClipboard()">
<!--			  <inline-svg :src="require('@/assets/img/copy_icon.svg')"/>-->
			  Save
		  </a>
	  </template>

	  <p v-if="hasError" class="validation-message">
        {{ error }}
      <template v-if="required">
        Required
      </template>
    </p>
  </fieldset>
</template>

<script>
import clipboard from "@/helpers/clipboard";
let id = 1;

export default {
	inheritAttrs: false,
	name: "InputField",
	// model: {
	// 	prop: 'value',
	// 	event: 'input'
	// },
	data: function () {
		return {
			width: this.minWidth,
			id: id++,
			wasActive: false,
			isActive: false,
			copyTooltip:'Copy',
		}
	},
	props: {
		value: { default: "" },
    bold: {
      default: () => false,
      type: Boolean,
    },
    textarea: {
      default: () => false,
      type: Boolean,
    },
		isSaved: {
			default: () => false,
			type: Boolean,
		},
		autosize: {
			default: () => false,
			type: Boolean,
		},
		maxWidth: {
			default: () => 0,
			type: Number,
		},
		minWidth: {
			default: () => 0,
			type: Number,
		},
		placeholder: {
			default: () => '',
		},
		selectAll: {
			default: () => false,
			type: Boolean,
		},
		small: {
			default: () => false,
			type: Boolean,
		},
		extraSmall: {
			default: () => false,
			type: Boolean,
		},
		flat: {
			default: () => false,
			type: Boolean,
		},
		isCopy: {
			default: () => false,
			type: Boolean,
		},
		isSave: {
			default: () => false,
			type: Boolean,
		},
		interactive: {
			default: () => false,
			type: Boolean,
		},
		icon: {
//            type: Object,
			required: false,
			default: () => null,
		},
		prepend: {
			required: false,
			default: () => null,
		},
		append: {
			required: false,
			default: () => null,
		},
		required: {
			type: Boolean,
			default: () => false,
		},
		info: {
			type: String,
			default: () => null,
		},
		label: String,
		error: {
			default: () => null
		},
		optional:{
			type:Boolean,
			default: false,
		},
		maxLength:{
			type:Number,
			default: () => null,
		}
	},

	computed: {
		style() {
			if (!this.autosize) return null
			return {
				// FIXME: hardcoded to include padding
				width: (this.width+24)+'px',
			}
		},
		hasError() {
			return !!this.error || ((this.required && !this.inputVal) && this.wasActive);
		},
		length() {
			return (this.inputVal || '').length
		},
		inputVal: {
			get() {
				return this.value;
			},
			set(val) {
				this.$emit('input', val);
			}
		}
	},
	methods: {
		onInput(ev) {
			if (this.autosize) {
				let w = this.$refs.mirror.scrollWidth;
				if (this.minWidth > 0 && w < this.minWidth) w = this.minWidth;
				else if (this.maxWidth > 0 && w > this.maxWidth) w = this.maxWidth;
				this.width = w;
			}
			this.$emit('input', ev)
		},
		onFocus(ev) {
			if (this.selectAll) ev.target.select();
			this.isActive=true;
		},
		copyToClipboard() {
			clipboard.copy(this.$refs.input);
			this.copyTooltip = "Copied!"
			setTimeout(()=>this.copyTooltip="Copy",1500)
		},
		focusInput() {
			this.$refs.input.focus();
		}
	},
	mounted() {
		if (this.autosize) {
			let w = this.$refs.mirror.scrollWidth;
			if (this.minWidth > 0 && w < this.minWidth) w = this.minWidth;
			else if (this.maxWidth > 0 && w > this.maxWidth) w = this.maxWidth;
			this.width = w;
		}
	}
}
</script>
<style lang="scss">
fieldset.input-field {
  display: flex;
  border: 2px solid #E4E6F1;
	background: #fff;
  border-radius: 8px;
	height: 41px;
	max-height: 41px;
  position: relative;
  transition: 0.15s all ease-in-out;
	padding: 0;
	margin-inline-start: 0;
	margin-inline-end: 0;
	font-size: 14px;
	//flex-direction: column;
	flex: 1;
  &.bold {
    input {
      font-weight: 600;
    }
  }
	input {
		font-size: inherit;
	}
	.is-optional {
		font-weight: 300;
		margin-left: 6px;
		//color: $grey;
	}
	&.textarea {
		height: auto;
	}

	// Small fix to background overlap.
	.info_icon {
		background: transparent;
	}
	&.small {
		.info_icon svg {
			height: 16px;
		}
	}
	&.autosize {
		box-sizing: content-box;
	}

	.input-mirror {
		visibility: hidden;
		position: absolute;
		top: 0;
		left: 0;
		height: 0;
		overflow: hidden;
		white-space: pre;
		font-size: inherit;
		font-family: inherit;
		font-weight: inherit;
		font-style: inherit;
		letter-spacing: inherit;
		text-transform: inherit;
	}
	&.extra-small {
		border: 1px solid #E4E6F1;
		border-radius: 4px;
		height: 24px;
		max-height: 24px;
		font-size: 12px;
		&:not(.active):not(.is-invalid):hover {
			border: 1px solid #bfc3dc;
			legend {
				color: #bfc3dc;
			}
		}
		input {
			padding: 0 6px 0 6px;
		}
		.append {
			padding: 3px 5px;
			border-left: none;
		}
    .input_copy_btn {
      width: 20px;
      height: 20px;
      right: 2px;
      svg {
        width: 24px;
        height: 24px;
      }
    }
  }
	.icon {
		margin-left: 5px;
		width: auto;
		height: auto;
	}

	&.small {
		border: 1px solid #E4E6F1;
		border-radius: 4px;
		height: 28px;
		max-height: 28px;
		&:not(.active):not(.is-invalid):hover {
			border: 1px solid #bfc3dc;
			legend {
				color: #bfc3dc;
			}
		}
		input {
			padding: 0 6px 0 6px;
		}
		.append {
			padding: 3px 5px;
			border-left: none;
		}
		.input_copy_btn {
			position: absolute;
			right: 1px;
			width: 24px;
			height: 24px;
			border-radius: 2px;
			overflow: hidden;
			:hover {
				border-radius: 2px;
			}
		}
		.save_btn {
			position: absolute;
			right: 2px;
			width: auto;
			height: 22px;
			:hover {
				border-radius: 2px;
			}
		}
		p.validation-message {
			font-size: 8px;
			font-weight: 400;
			top: -8px;
			right: 8px;
		}
	}
	&.is-invalid{
		border-color: #FB595B;
		legend {
			color: #FB595B;
		}
		.icon svg path {
			fill: #FB595B;
		}
	}
	&.flat {
		border-width: 1px;
		border-color: transparent;
		&.active {
			//border-color: transparent;
			border-width: 1px;
		}
	}
	textarea {
		padding: 10px;
		flex: 1;
		border-radius: 5px;
	}
	&.has-max-length {
		textarea {
			resize: none;
		}
	}
	&.textarea {
		max-height: unset;
	}
  .prepend,.append,.max-length {
    display: flex;
    border-right: 1px solid whitesmoke;
    white-space: nowrap;
    align-items: center;
    padding: 3px 8px;
    color: #b3b3b3;
    border-radius: 5px 0 0 5px;
    font-weight: 400;
    font-size: 14px;
    line-height: 145%;
    letter-spacing: 0.02em;
  }
	.append {
		border-radius: 0 5px 5px 0;
		border-right: none;
		border-left: 1px solid whitesmoke;
	}
	.max-length {
		border-radius: 5px 0 5px 0;
		border-top: 1px solid whitesmoke;
		border-left: 1px solid whitesmoke;
		border-right: none;
		padding: 2px 8px;
		font-size: 13px;
		margin-top: auto;
		&.red {
			color: $light-red;
		}
	}

	legend {
    font-weight: 500;
    font-size: 12px;
    line-height: 150%;
    letter-spacing: 0.02em;
    color: #C5C7D2;
    margin-left: 5px;
    padding: 0 5px;
    display: flex;
    position: absolute;
    top: -10px;
    left: 10px;
    background: white;
  }
	&.is-save {
		input {
			padding-right: 55px;
		}
	}
	.save_btn {
		position: absolute;
		top: 50%;
		transform: translateY(-50%);
		right: 15px;
		width: 26px;
		height: 26px;
		background: #97AACD;
		display: flex;
		align-items: center;
		justify-content: center;
		cursor: pointer;
		border-radius: 4px;
		overflow: hidden;
		padding: 2px 8px;
		color: white;
		&:hover {
			background: #7282a0;
		}
		svg path {
			fill: #fff;
		}
		svg {
			min-width: 40px;
		}
	}
  &:last-child {
    margin-bottom: 0;
  }

  input, .value {
    position: relative;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    background: transparent;
    border: none;
    padding: 0 15px;
    display: flex;
    align-items: center;
    font-size: inherit;
    letter-spacing: 0.02em;
    color: #636A7B;
  }
  .value {
    color: #C5C7D2;
    pointer-events: none;
  }
  .text {
    padding-left: 150px;
  }
  &.active {
    border-color: #4368E0;
    legend {
      color: #4368E0;
    }

    .icon svg path {
      fill: #4368E0;
    }
  }
  &:not(.active):not(.is-invalid):hover {
    border: 2px solid #bfc3dc;
    legend {
      color: #bfc3dc;
    }
  }

  p.validation-message {
    position: absolute;
    top: -4px;
    right: 10px;
    margin: 0;
    background: white;
    padding: 0 5px;
    color: #FB595B;
    font-weight: 400;
    font-size: 10px;
    opacity: 0;
    animation: appear 1 0.3s forwards;
  }
  .icon {
    display: flex;
    white-space: nowrap;
    align-items: center;
    padding: 3px 8px 3px 0;
    color: #b3b3b3;
    border-radius: 5px 0 0 5px;
    font-weight: 400;
    font-size: 14px;
    line-height: 145%;
    letter-spacing: 0.02em;
    position: relative;
    justify-content: center;
    pointer-events: none;
  }
	input, textarea {
		&::placeholder {
      opacity: 1;
			color: $dark-grey;
		}
	}
}
</style>
