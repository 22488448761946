import {
    shadows, border, sticky,id,padding
} from './fields/'

export default {
    tag: 'expressCheckout',
    labelTitle: '',
    labelColor: '',
    id,
    attributes: {
        basic: {
            // color: { title: 'Color', comp: 'colorPicker', value: '#252A32' },
            margin: { title: 'Margin', comp: 'marginPadding', value: { top: 10, left: 0, right: 0, bottom: 0 } },
            backgroundColor: { title: 'Background color', comp: 'colorPicker', value: '#FFFFFF' },
            props: {
                title: 'Props', comp: 'expressCheckoutProps',
                value: {
                    // not in use for now.
                }
            },
            padding,
            border,
            // openPopup: { title: 'Edit payment options', comp: 'PopupOpenBtn', value: 'payment-methods' },
            shadows
        },
        advanced: {
            cssId: { title: 'ID', comp: 'TextInput', value: '' },
            cssClasses: { title: 'CSS Classes', comp: 'MultiSelectItem', value: [], options: [] },
            sticky
        },
    },
}
