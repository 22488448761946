<template>
  <a @click="onClick" :href="href"
     v-bind="$attrs"
     class="button"
    :class="{'brdr_btn':border, 'link':isLink, 'flat':flat, 'small-btn':small,'extra-small':extraSmall,
    'slim':slim, '__new':newStyle, 'icon-btn':icon||rightIcon, red, 'red_brdr_btn':red, 'loading':loading,
     'locked':locked, 'disabled':(disabled || loading || locked), 'small-icon':smallIcon}"
  >
    <template v-if="loading">
      <v-spinner></v-spinner>
    </template>

     <inline-svg class="icon" v-if="icon" :src="icon" />
<!--    <img class="icon" v-if="icon" :src="icon" alt="">-->

	  <input class="upload-file-btn" type="file" @change="$emit('change',$event)"
	         :accept="accept" v-if="upload" ref="uploadBtn"  />

    <template v-if="label">
      <span class="label">
        {{ label }}
      </span>
    </template>
    <template v-else>
      <slot></slot>
    </template>

	  <inline-svg class="icon right" v-if="rightIcon" :src="rightIcon" />

	  <inline-svg v-if="locked && hasLabel" v-tippy :content="locked?'Access denied':false"
	              @click.stop @mousedown.stop
	              class="lock" :src="require('@/assets/img/lock.svg')"/>

  </a>
</template>

<script>

import VSpinner from "@/components/base/v-spinner";
export default {
  components: {VSpinner},
  inheritAttrs: false,
  name: "VButton",

  data: function (){
      return {
      }
  },

  props: {
		// New button style... should be deprecated eventually.
    newStyle:{
      type:Boolean,
      default:false
    },
    smallIcon:{
      type:Boolean,
      default:false
    },
	  accept: {
		  default: '',
		  type: String,
	  },
	  upload: {
		  default: false,
		  type: Boolean,
	  },
	  slim: {
		  default: false,
		  type: Boolean,
	  },
	  small: {
		  default: false,
		  type: Boolean,
	  },
	  extraSmall: {
		  default: false,
		  type: Boolean,
	  },
    flat:{
      default: false,
      type: Boolean,
    },
    icon: {
      default: null,
    },
	  disabled: {
		  default: false,
		  type: Boolean,
	  },
	  locked: {
		  default: false,
		  type: Boolean,
	  },
    loading: {
      default: false,
      type: Boolean,
    },
    red: {
      default: false,
      type: Boolean,
    },
    border: {
      default: false,
      type: Boolean,
    },
    href: {
      default: null,
      type: String,
    },
    label: {
      default: "",
	    type: String,
    },
	  isLink:{
			default:false,
		  type: Boolean
	  },
	  rightIcon:{
			default:null,
	  }
  },
  methods: {
		onClick(ev) {
			this.$emit('click', ev)

			if (this.upload) this.$refs.uploadBtn.click()
		},
    onInput() {
      this.$emit('input', this.value)
    },
    onChange() {
      this.$emit('change', this.value)
    }
  },
  computed: {
  	hasLabel(){
  		return this.$slots.default || this.label;
	  },
    hasError() {
        return !!this.error && this.wasActive;
    }
  }
};
</script>
<style lang="scss" scoped>
.button {
	&.extra-small {
		&.red {
			svg.icon {
				fill: $light-red;
				path {
					fill: $light-red !important;
				}
			}
		}
	}
}
</style>
<style lang="scss" scoped>
.button {
	white-space: nowrap;
	&.__new {
		height: 30px;
		padding: 1px 12px;
		border: 1px solid #3756b9;
		box-shadow: 0 1px 2px rgba(16, 24, 40, 0.08);
		width: fit-content;
		font-size: 12px;
		font-weight: 500;
    color: #F6F9FE;
    background: $blue;
    border-radius: 6px;
    .icon {
      color: #F6F9FE;
      fill: #F6F9FE;
    }
    &.small-icon {
      .icon {
        width: 10px;
        height: 10px;
        min-width: 10px;
      }
    }
    &.flat {
      background: none;
      border: none;
      color: $darker-grey;
      fill: $darker-grey;
      box-shadow: none;
      .icon {
        color: $darker-grey;
        fill: $darker-grey;
      }
      &:hover {
        background: $light-grey;
      }
    }
    &.brdr_btn {
      border: 1px solid #D0D5DD;
      color: #344054;
      background: white;
      &:hover {
        color: #344054;
        background: $light-silver;
        border-color: #bcc2ca;
      }
      .icon {
        color: #344054;
        fill: #344054;
      }
    }
    &.red {
      color: #FB595B;
      border-color: #FB595B;
      &:not(.brdr_btn) {
        background: #FB595B;
      }

      &:hover {
        color: #FB595B !important;
        border-color: #FB595B !important;
        background: #fff3f3 !important;
      }
    }
		&.extra-small {
			width: 30px;
		}
		&:hover {
      background: #4e74f1;
      color: #F6F9FE;
      border-color: #4667d4;
    }
    &.disabled {
      border-color: #e9e9e9 !important;
      //background: none !important;
      //color: #D0D5DD !important;
      box-shadow: none;
    }
	}
	&.extra-small {
		height: 28px;
		width: 28px;
		padding: 1px;
		.icon {
			height: 16px;
			width: 16px;
			min-width: 20px;
			margin: auto;
		}
		&.red {
			.icon {
				fill: $light-red;
			}
		}
	}
	&.small-btn {
		color: $darker-grey;
		&.red {
			color: $light-red;
		}
		svg {
			margin: auto;
		}
	}
	.upload-file-btn {
		display: none;
	}
  &.loading {
    .label {
      opacity: 0;
    }
  }
	&.link {
		padding: 0!important;
		.icon, .icon.right {
			margin-left: 0;
			transition: all .2s ease-in-out;
		}
		&:hover {
			text-decoration: underline;
			background: none;
			.icon {
				&.right {
					margin-left: 3px;
				}
			}
		}
	}

	.lock {
		width: 14px;
		height: 14px;
		margin-left: auto;
		margin-right: 0 !important;
		position: absolute;
		right: 8px;
		z-index: 1;
		pointer-events: auto;
	}

}
</style>

